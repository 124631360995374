<template>
  <!-- 设置网点 -->
  <div class="page-container">
    <div class="page-top-tool">
      <el-page-header @back="goBack" content="设置网点"></el-page-header>
    </div>
    <div class="form-layout">
      <el-form :model="form" :inline="true" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <!-- <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="员工编号" prop="empcode">
              <el-input v-model="form.empcode" size="mini" placeholder="请输入" style="width: 300px;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="员工姓名" prop="empname">
              <el-input v-model="form.empname" size="mini" placeholder="请输入" style="width: 300px;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model="form.phone" size="mini" placeholder="请输入" style="width: 300px;margin-right: 10px;"></el-input>
              <el-tooltip class="item" effect="dark" content="用于登录小程序" placement="right">
                <span class="el-icon-info"></span>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="初始密码" prop="pwd">
              <el-input v-model="form.pwd" size="mini" placeholder="请输入" style="width: 300px;"></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="入职岗位">
              <el-radio v-model="form.role" disabled label="2">规划师</el-radio>
              <el-radio v-model="form.role" disabled label="1">团队经理</el-radio>
            </el-form-item>
          </el-col>
          <el-col v-if="form.role == '2'" :span="12">
            <el-form-item label="所属团队">
              <el-select v-model="form.fcode" size="mini" filterable clearable placeholder="请选择" style="width: 300px;" @change="onChangeFcode">
                <el-option v-for="item in employeeOptions" :key="item.empcode" :label="item.empname" :value="item.empcode"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-else-if="form.role == '1'" :span="12">
            <el-form-item label="管理网点">
              <el-button plain size="mini" :loading="selectStoreLoading" @click="onSelectStore">选择网点</el-button>
              <span v-if="selectStoreValue.length > 0" style="margin-left: 10px;">已选 {{ selectStoreValue.length }} 个网点</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col v-if="form.role == '2'" :span="12">
            <el-form-item label="管理网点">
              <el-button plain size="mini" :disabled="form.fcode == ''" :loading="selectStoreLoading" @click="onSelectStore">选择网点</el-button>
              <el-tooltip class="item" effect="dark" content="先选择团队" placement="right" style="margin-left: 10px;">
                <span class="el-icon-info"></span>
              </el-tooltip>
              <span v-if="selectStoreValue.length > 0" style="margin-left: 10px;">已选 {{ selectStoreValue.length }} 个网点</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 选择管理网点 -->
      <el-dialog title="选择网点" :visible.sync="dialogStoreVisible" :show-close="false">
        <div class="select-store-dialog">
          <el-transfer
            filterable
            :filter-method="storeFilterMethod"
            filter-placeholder="请输入"
            :titles="['可选网点', '已选网点']"
            v-model="selectStoreValue"
            :data="storeAllList">
          </el-transfer>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="onCancelStoreSelect">取 消</el-button>
          <el-button type="primary" @click="onStoreSelectedSubmit">确 定</el-button>
        </div>
      </el-dialog>
      <div class="bottom-submit-btn">
        <el-button type="primary" :loading="submitLoading" size="mini" @click="submitForm('ruleForm')">提 交</el-button>
        <!-- <el-button size="mini" @click="resetForm('ruleForm')">重 置</el-button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetStorePage',
  data() {
    // const validateFname = (rule, value, callback) => {
    //   if (this.form.role == '1') {
    //     callback()
    //   } else {
    //     if (value == '') {
    //       callback(new Error('请选择团队'))
    //     }
    //   }
    // }
    const validatePhone = (rule, value, callback) => {
      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(value)) {
        callback(new Error('手机号格式不正确'))
      } else {
        callback()
      }
    }
    // const validateStorename = (rule, value, callback) => {
    //   if (this.form.storename.length > 0) {
    //     callback()
    //   } else {
    //     callback(new Error('请选择网点'))
    //   }
    // }
    return {
      form: {
        id: '',
        empcode: '', // 员工编号
        empname: '', // 员工姓名
        role: '2', // 角色，1经理，2规划师
        storename: [], // 所属网点名称
        phone: '',
        pwd: '',
        remark: '',
        fname: '', // 所属团队
        fcode: '', // 团队经理编码
      },
      dialogStoreVisible: false, // 选择网点弹框
      selectStoreValue: [], // 选择的网点
      storeAllList: [], // 过滤后的所有网点
      allStoreList: [], // 所有网点原数据
      employeeOptions: [], // 所有经理数据
      selectStoreLoading: false, // 穿梭框加载网点信息loading
      submitLoading: false, // 提交加载
      // 网点选择穿梭框搜索事件
      storeFilterMethod(query, item) {
        return item.label.includes(query)
      },
      rules: {
        empcode: [
          { required: true, message: '请输入员工编号', trigger: 'blur' }
        ],
        empname: [
          { required: true, message: '请输入员工姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请选择手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        pwd: [
          { required: true, message: '请输入初始密码', trigger: 'blur' }
        ],
        // fname: [
        //   { required: true, message: '请输入团队', trigger: 'blur' },
        //   { validator: validateFname, trigger: 'change' }
        // ],
        // storename: [
        // { validator: validateStorename, trigger: 'change' }
        // ]
      }
    };
  },
  watch: {
    'form.role': {
      handler() {
        this.storeAllList = []
        this.selectStoreValue = []
        this.form.storename = ''
        this.form.fname = ''
      }
    },
    'form.fcode': {
      handler() {
        this.storeAllList = []
        this.selectStoreValue = []
        this.form.storename = ''
      }
    },
    'form.storename': {
      handler(val) {
        if (val.length > 0) {
          this.$refs.ruleForm.clearValidate('storename')
        }
      }
    }
  },
  mounted() {
    const employee = sessionStorage.getItem('updateEmployee')
    if (employee) {
      this.form = JSON.parse(employee)
    } else {
      this.$message.warning('参数有误，请重新选择要修改的员工')
      this.$router.push({
        path: '/employee'
      })
    }
    this.initData()
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    // 获取初始化数据
    async initData() {
      // const storeResp = await this.$http({
      //   method: 'post',
      //   url: 'store_findall'
      // })
      // const empResp = await this.$http({
      //   method: 'post',
      //   url: 'employee_findall'
      // })
      // if (storeResp.errno == 0) {
      //   this.allStoreList = storeResp.data.data
      // } else {
      //   this.$message.error('网点加载失败')
      // }
      // if (empResp.errno == 0) {
      //   this.allEmployeeList = empResp.data.data
      // } else {
      //   this.$message.error('员工加载失败')
      // }

      const allStoreList = sessionStorage.getItem('allStoreList')
      if (allStoreList) {
        this.allStoreList = JSON.parse(allStoreList)
      }
      const allEmployeeList = sessionStorage.getItem('allEmployeeList')
      if (allEmployeeList) {
        this.allEmployeeList = JSON.parse(allEmployeeList)
      }

      this.employeeOptions = this.allEmployeeList.filter(item => {
        return item.role == '1'
      })

    },
    // 团队经理管理网点选择事件
    async onSelectStore() {
      this.storeAllList = []
      this.selectStoreLoading = true
      // const storeAllList = this.allStoreList.filter(item => {
      //   if (this.form.role == '1') {
      //     return item.fname == '' || item.fname == null
      //   } else if (this.form.role == '2') {
      //     return item.fcode == this.form.fcode && (item.sname == '' || item.sname == null)
      //   }
      // })
      // storeAllList.forEach((item, index) => {
      //   this.storeAllList.push({
      //     key: item.storecode,
      //     label: item.storename
      //   })
      // })
      const resp = await this.$http({
        method: 'post',
        url: 'store_findall'
      })
      if (resp.errno != 0) {
        this.$message.error('网点查询失败')
        return
      }
      const storeAllList = resp.data.data.filter(item => {
        if (this.form.role == '1') {
          return item.fname == '' || item.fname == null
        } else if (this.form.role == '2') {
          return item.fcode == this.form.fcode && (item.sname == '' || item.sname == null)
        }
      })
      storeAllList.forEach((item, index) => {
        this.storeAllList.push({
          key: item.storecode,
          label: item.storename
        })
      })

      this.dialogStoreVisible = true
      this.selectStoreLoading = false
    },
    // 确定网点选择
    onStoreSelectedSubmit() {
      this.form.storename = this.selectStoreValue
      this.dialogStoreVisible = false
    },
    // 取消网点选择
    onCancelStoreSelect() {
      this.dialogStoreVisible = false
      if (this.form.storename.length > 0) {
        this.selectStoreValue = this.form.storename
      } else {
        this.storeAllList = []
        this.selectStoreValue = []
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 选择团队事件
    onChangeFcode(fcode) {
      this.employeeOptions.forEach(item => {
        if (item.empcode == fcode) {
          this.form.fname = item.empname
        }
      })
    },
    submitForm(formName) {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
            this.submitLoading = true
            // const params = {
            //   empcode: this.form.empcode,
            //   empname: this.form.empname,
            //   role: this.form.role,
            //   phone: this.form.phone,
            //   pwd: this.form.pwd,
            //   fname: this.form.fname
            // }
            const storelist = []
            this.allStoreList.forEach(item => {
              if (this.form.storename.includes(item.storecode)) {
                const obj = {
                  id: item.id
                }
                if (this.form.role == '1') {
                  obj.fcode = this.form.empcode
                  obj.fname = this.form.empname
                } else {
                  obj.scode = this.form.empcode
                  obj.sname = this.form.empname
                }
                storelist.push(obj)
              }
            })

            const storeResp = await this.$http({
              method: 'post',
              url: 'store_updatebth',
              params: {
                storelist: JSON.stringify(storelist)
              }
            })

            if (storeResp.data) {
              let resCount = 0
              storeResp.data.forEach(item => {
                if (item.errno == 0) {
                  resCount++
                }
              })
              this.$message.success(`成功更新 ${resCount} 条数据`)
              // this.form = {
              //   empcode: '', // 员工编号
              //   empname: '', // 员工姓名
              //   role: '2', // 角色，1经理，2规划师
              //   storename: [], // 所属网点名称
              //   phone: '',
              //   pwd: '',
              //   remark: '',
              //   fname: '', // 所属团队
              //   fcode: '', // 团队经理编码
              // }
              // this.selectStoreValue = []
            }
            const empResp = await this.$http({
              method: 'post',
              url: 'employee_update',
              params: {
                id: this.form.id,
                fcode: this.form.fcode,
                fname: this.form.fname
              }
            })
            if (empResp.errno == 0) {
              this.$message.success('员工信息更新成功')
            } else {
              this.$message.error('员工信息更新失败')
            }
            this.submitLoading = false
            this.$router.back()
        } else {
          return false;
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 20px 25px;
  overflow-y: scroll;

  .page-top-tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }

  .form-layout {
    margin: 30px 0px;
    padding: 10px 30px;
  }

  .bottom-submit-btn {
    padding: 10px 30px;
  }
}

/* 修改滚动条样式 */
.page-container::-webkit-scrollbar {
	width: 4px;
}
.page-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}
.select-store-dialog {
  // text-align: center;
  padding-left: 50px;
  height: 400px;
}
.dialog-footer{
  position: absolute;
  bottom: 50px;
  right: 50px;
}
::v-deep .el-dialog {
  width: 85%;
  height: 600px;
  top: -20px;
}
.el-transfer {
  width: 100%;
  height: 400px;
}
::v-deep .el-transfer-panel {
    width: 40%;
}
::v-deep .el-checkbox-group.el-transfer-panel__list.is-filterable{
  height: 300px;
}
::v-deep .el-transfer-panel {
  height: 400px;
}
</style>